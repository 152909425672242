import React, { useEffect, useRef } from "react";
import CustomerLayout from "../components/CustomerLayout";
import "../styles/CustomerCart.css";
import { useState } from "react";
import { Trash } from "@phosphor-icons/react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  TextField,
} from "@mui/material";
import { Minus, Plus } from "@phosphor-icons/react";
import { CustomerContext } from "../context/CustomerContext";
import { useContext } from "react";
import { confirmAlert, errorAlert, successAlert } from "../components/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom/dist";
import ticket from "../Images/CartTicket.png";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CustomerAddressModal from "../components/CustomerAddressModal";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";
import EmptyPage from "./EmptyPage";

function CustomerCart(props) {
  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [giftCardLoading, setGiftCardLoading] = useState(false);

  const [cartData, setCartData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const navigate = useNavigate();
  const { customerData, accessToken, handleAuthExpiry, logout } =
    useContext(CustomerContext);
  const [currentTotalAmount, setCurrentTotalAmount] = useState();
  //console.log(customerData);
  // const navigate = useNavigate();

  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [includeWalletAmount, setIncludeWalletAmount] = useState(false);

  const isAuth = () => {
    if (customerData?.result?.userID) {
      //console.log('Auth')
      return true;
    }
    //console.log('No auth')
    return false;
  };

  const fetchCartData = async () => {
    setCartLoading(true);
    try {
      //console.log(customerData?.result?.userID)
      const response = await fetch(
        `${API_BASE_URL}/cart/api/v1/getbybuyerID?buyerID=${customerData?.result?.userID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setCartLoading(false);
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (Array.isArray(data?.result)) {
        // console.log("cartdata", data);
        // console.log(data?.result?.length());

        setCartData(data?.result);
        console.log(data?.result);
        return data?.result;
      } else {
        toastRef.current.showToast("Error Fetching Cart Data", "error");
      }
    } catch (e) {
      setCartLoading(false);
      // console.log(e);
    }
  };

  const fetchGiftCardData = async () => {
    setGiftCardLoading(true);
    try {
      //console.log(customerData?.result?.userID)
      const response = await fetch(
        // `${API_BASE_URL}/cart/api/v1/getbybuyerID?buyerID=${customerData?.result?.userID}`,
        // `https://dev-api.myhraki.com/web/coupons/api/v1/GetVoucherStatus?PurchaseUserid=${customerData?.result?.userID}`,
        `${API_BASE_URL}/coupons/api/v1/GiftCardLedger?UserId=${customerData?.result?.userID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setGiftCardLoading(false);
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      // .result?
      // if (data?.result) {

      //   setGiftCardAmount(
      //     data?.result?.reduce(
      //       (acc, total) =>
      //         acc +
      //         parseFloat(total?.totalAmount) -
      //         parseFloat(total?.usedAmount),
      //       0
      //     )
      //   );
      if (data?.result) {
        const sortedData = data?.result?.sort((a, b) => b.id - a.id);
        const mostRecentEntry = sortedData[0];
        // setGiftCardAmount(parseFloat(mostRecentEntry?.totalAmount || 0));
        setGiftCardAmount(
          parseFloat((mostRecentEntry?.totalAmount || 0).toFixed(2))
        );
        console.log({ giftCardAmount });
        return data?.result;
      } else {
        toastRef.current.showToast("Error Fetching Cart Data", "error");
      }
    } catch (e) {
      setGiftCardLoading(false);
      // console.log(e);
    }
  };

  const checkCartAdd = async () => {
    try {
      //console.log(customerData?.result?.userID)
      const response = await fetch(
        `${API_BASE_URL}/cart/api/v1/getbybuyerID?buyerID=${customerData?.result?.userID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        setLoading(false);
        return;
      }
      if (data?.result[0]?.stopCart === false) {
        return false;
      } else if (data?.result[0]?.stopCart === true) {
        return true;
      } else {
        toastRef.current.showToast("Error Fetching Cart Data", "error");
      }
    } catch (e) {
      //console.log(e)
    }
  };

  useEffect(() => {
    if (customerData?.result?.userID) {
      fetchCartData();
      fetchDiscountData();
      fetchAddressData();
      fetchGiftCardData();
    } else {
      setLoginModalOpen(true);
    }
  }, [customerData?.result?.userID]);

  const [tab, setTab] = useState("cart");

  const handleCartRestrictions = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/cart/api/v1/CartVisibility`,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
          method: "PUT",
          body: JSON.stringify({
            cartid: cartData?.[0]?.cartId,
            cartstatus: false,
          }),
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (data?.result === true) {
        return true;
      }
      return false;
    } catch (e) {
      //console.log(e)
    }
  };

  async function handleProceedToAddress() {
    setCurrentTotalAmount(totalAmount);
    await calculateAllProductQty(cartData);
    // await fetchAddressData();
  }

  // async function clearCart() {
  //     try {

  //         cartData?.forEach(item => deleteItemFromCart(item.itemId))
  //         //    fetchCartData();
  //     } catch {
  //         errorAlert('', 'Could not clear cart')
  //     }
  // }

  async function deleteItemFromCart(item) {
    try {
      const result = await fetch(
        `${API_BASE_URL}/cart/api/v1/remove/${cartData[0]?.cartId},${item}`,
        {
          method: "Delete",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await result.json();
      // const data = await response.json()
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (response.message === "True") {
        // successAlert('', 'Item deleted from Cart')
        toastRef.current.showToast("Item Deleted", "success");
        fetchCartData();
      } else if (cartData?.length === 1 && response?.message === "False") {
        // successAlert('', 'Item deleted from Cart')
        toastRef.current.showToast("Item Deleted", "success");
        fetchCartData();
      } else {
        // errorAlert('', 'Something Went wrong')
      }
    } catch (e) {
      //console.log(e)
    }
  }

  const [discountData, setDiscountData] = useState([]);
  const fetchDiscountData = async () => {
    try {
      //console.log(customerData?.result?.userID)
      const response = await fetch(
        `${API_BASE_URL}/coupons/api/v1/GetAllCoupon`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (data?.result) {
        // console.log('data', data)
        //console.log(totalAmount);
        setDiscountData(
          data?.result?.result?.filter(
            (item) =>
              item?.status === "Active" &&
              item?.minAmountCart >= totalAmount - totalDiscount
          )
        );
      } else {
        // toastRef.current.showToast('Error Fetching Discount Data' , 'error')
      }
    } catch (e) {
      //console.log(e)
    }
  };

  const [selectedCouponApply, setSelectedCouponApplied] = useState();
  const [expanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  // const editCartData = async () => {

  // }

  const [couponCodeText, setCouponCodeText] = useState("");
  const [filteredDiscount, setFilteredDiscount] = useState();
  useEffect(() => {
    setFilteredDiscount(discountData);
  }, [discountData]);

  useEffect(() => {
    setExpanded(true);
    const data = discountData;
    const enteredCouponCode = couponCodeText.trim();
    // //console.log(enteredCouponCode);
    console.log(discountData)
    setFilteredDiscount(
      data?.filter((item) => item.couponCode.includes(enteredCouponCode))
    );
    // setDiscountData(filteredDiscountData);
  }, [couponCodeText]);

  const updateCartData = async (sku, newQty) => {
    // Find the item in cartData and update its quantity
    setLoading(true);
    const checkAdd = await checkCartAdd();
    if (checkAdd === false) {
      toastRef.current.showToast(
        "Please complete the initiated transaction before adding to cart",
        "error"
      );

      setLoading(false);
      return;
    }

    const updatedCartData = cartData.map((item) => {
      if (item.sku === sku) {
        async function editCartData(item) {
          try {
            // Make your API call here to add the item to the cart
            const response = await fetch(
              `${API_BASE_URL}/cart/api/v1/update
                        `,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                  CartId: item.cartId,
                  BuyerID: customerData?.result?.userID,
                  Products: [
                    {
                      ItemId: item.itemId,
                      ImageFilename: item.imageFilename,
                      Title: item.title,
                      Description: item.desc,
                      SKU: item.sku,
                      Qty: newQty,
                      UnitCost: item.unitCost,
                      CategoryId: item.categoryId,
                      GST: item?.gst,
                    },
                  ],
                }),
              } 
            );

            if (response.ok) {
              const responseData = await response.json();
              //   successAlert('', 'Added to Cart')
              //console.log(responseData);
              fetchCartData();
              setLoading(false);

              //   return responseData; // You can return the response data if needed
            } else {
              setLoading(false);
              const handleAuthError = handleAuthExpiry(response);
              if (handleAuthError === true) {
                logout();
                return;
              }
              toastRef.current.showToast("Could not Add to cart", "error");
            }
          } catch (error) {
            toastRef.current.showToast("Could not add to cart", "error");
            setLoading(false);

            // console.error('Error adding item to cart:', error);
            throw error;
          }
        }
        if (newQty >= 1) {
          editCartData(item);
        } else {
          confirmAlert(
            "",
            "Are you sure you want to delete this item from cart?",
            () => {
              deleteItemFromCart(item.itemId);
            }
          );
        }
      }
      return item;
    });

    //console.log('After Updated', updatedCartData);
    // Update cartData state
    // setCartData(updatedCartData);
    // fetchCartData();
  };

  function calculateTotalAmount(cartData) {
    let totalAmount = 0;
    let totalDiscount = 0;
    let shippingAmount = 0;

    let walletAmount = includeWalletAmount === true ? giftCardAmount : 0;
    console.log(walletAmount);
    const couponDiscount = selectedCouponApply?.amount
      ? parseFloat(selectedCouponApply.amount)
      : 0;
    let totalGst = 0;
    let total = 0;
    console.log(walletAmount);
    cartData?.forEach((item) => {
      const itemPrice = parseFloat(item.price);
      const sellerPrice = parseFloat(item.sellerprice);
      console.log(item?.gst);

      // Calculate total for each item with added shipping cost
      const itemTotal = itemPrice * item.qty;

      // Calculate discount for each item based on the selling price
      const discountTotal = (itemPrice - sellerPrice) * item.qty;

      // Calculate GST for each item
      console.log(item, itemTotal, discountTotal, item?.gst);
      const gstTotal =
        ((parseFloat(itemTotal) - parseFloat(discountTotal)) *
          (item?.gst ? parseFloat(item?.gst) : 0)) /
        100;

      // Add shipping cost for each item
      shippingAmount += 100;

      // Accumulate totals
      totalAmount += itemTotal;
      totalDiscount += discountTotal;
      totalGst += gstTotal;
      total +=
        itemTotal +
        gstTotal +
        shippingAmount -
        totalDiscount -
        selectedCouponApply -
        walletAmount;
    });

    // Add coupon discount to the total discount
    // totalDiscount += couponDiscount;

    // Add shipping cost to the total amount
    // totalAmount += shippingAmount;

    return {
      totalAmount,
      totalDiscount,
      couponDiscount,
      totalGst,
      shippingAmount,
      walletAmount,
      total,
    };
  }

  const {
    totalAmount,
    totalDiscount,
    couponDiscount,
    totalGst,
    shippingAmount,
    walletAmount,
    total,
  } = calculateTotalAmount(cartData);

  useEffect(() => {
    calculateTotalAmount(cartData);
  }, [includeWalletAmount]);

  const fetchAddressData = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/address/api/v1/getall?userId=${customerData?.result?.userID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (data?.result[0]?.id) setAddressData(data?.result);
      //console.log("Address Data:", data?.result);
    } catch {}
  };

  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    fetchAddressData();
    setOpenModal(false);
  };

  const checkDeliverable = async (code) => {
    try {
      const response = await fetch(`${API_BASE_URL}/logistic/api/v1/${code}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      if (data?.result?.success === true) {
        return true;
      } else {
        toastRef.current.showToast(
          "Cannot deliver to required location",
          "error"
        );
        return false;
      }
    } catch (e) {
      //console.log(e)
    }
  };

  const [selectedAddress, setSelectedAddress] = useState(null);

  const handleAddressChange = (event) => {
    const selectedId = event.target.value;
    const selectedAddress = addressData.find(
      (address) => address.id.toString() === selectedId
    );
    setSelectedAddress(selectedAddress);
  };
  useEffect(() => {
    addressData && setSelectedAddress(addressData[0]);
  }, [addressData]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);

  const handleShippingAddressChange = (event) => {
    const selectedId = event.target.value;
    const selectedShippingAddress = addressData.find(
      (address) => address.id.toString() === selectedId
    );
    setSelectedShippingAddress(selectedShippingAddress);
  };
  useEffect(() => {
    // Set the initial selected address when the addressData prop changes
    addressData && setSelectedShippingAddress(addressData[0]);
  }, [addressData]);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const pay = async () => {
    setPaymentLoading(true);
    const delivery = await checkDeliverable(selectedAddress?.pincode);
    if (!delivery) {
      setPaymentLoading(false);
      return;
    }
    const response = await fetchCartData();
    const result = calculateTotalAmount(response);
    if (result?.totalAmount !== currentTotalAmount) {
      toastRef.current.showToast(
        "There are some changes in Cart, Please review",
        "error"
      );

      setTab("cart");
      setPaymentLoading(false);

      return;
    }
    const data = await handleCartRestrictions();

    if (data === true) {
      const requestData = {
        userId: customerData?.result?.userID,
        Name: customerData?.result?.fullName,
        Email: customerData?.result?.email,
        PhoneNumber: customerData?.result?.mobileNumber,
        DeliveryAddress: selectedAddress.addressLine1,
        DeliveryPlace: selectedAddress.city,
        DeliveryState: selectedAddress.state,
        DeliveryPincode: selectedAddress.pincode,
        BillingAddress: selectedShippingAddress.addressLine1,
        BillingPlace: selectedShippingAddress.city,
        BillingState: selectedShippingAddress.state,
        BillingPincode: selectedShippingAddress.pincode,
        PaymentMode: "Online",
        Amount:
          // totalAmount +
          // totalGst +
          // shippingAmount -
          // totalDiscount -
          // (selectedCouponApply?.amount ? selectedCouponApply?.amount : 0) -
          // walletAmount,

          totalAmount +
            totalGst +
            shippingAmount -
            totalDiscount -
            (selectedCouponApply?.amount > 0
              ? selectedCouponApply?.amount
              : 0) -
            walletAmount >
          0
            ? totalAmount +
              totalGst +
              shippingAmount -
              totalDiscount -
              (selectedCouponApply?.amount > 0
                ? selectedCouponApply?.amount
                : 0) -
              walletAmount
            : 0,
        cartId: cartData[0]?.cartId,
        planID: 0,
        shipcost: shippingAmount,
        couponID: selectedCouponApply ? selectedCouponApply?.couponCode : "",
        planMonthsCount: 0,
        giftCardAmount:
          walletAmount -
          (walletAmount -
            (totalAmount +
              totalGst +
              shippingAmount -
              totalDiscount -
              (selectedCouponApply?.amount > 0
                ? selectedCouponApply?.amount
                : 0)) >
          0
            ? walletAmount -
              (totalAmount +
                totalGst +
                shippingAmount -
                totalDiscount -
                (selectedCouponApply?.amount > 0
                  ? selectedCouponApply?.amount
                  : 0))
            : 0),
      };
      console.log(
        walletAmount >=
          totalAmount +
            totalGst +
            shippingAmount -
            totalDiscount -
            (selectedCouponApply?.amount ? selectedCouponApply?.amount : 0) -
            walletAmount
      );
      console.log(requestData);
      // return;
      if (
        walletAmount >=
        totalAmount +
          totalGst +
          shippingAmount -
          totalDiscount -
          (selectedCouponApply?.amount ? selectedCouponApply?.amount : 0)
      ) {
        console.log("Paid");

        try {
          const response = await fetch(
            `${API_BASE_URL}/payments/api/v1/ZeroPaymentRequestPayment`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestData),
            }
          );

          const responseData = await response.json();
          console.log(responseData);
          const handleAuthError = handleAuthExpiry(response);
          if (handleAuthError === true) {
            logout();
            return;
          }
          if (responseData?.result === "Completed") {
            toastRef.current.showToast("Order Placed Successfully!", "success");
            setPaymentLoading(false);
            // setTab('cart');
            navigate('/');
          }
        } catch (e) {
          setPaymentLoading(false);

          //console.log(e)
        }
      } else {
        try {
          const response = await fetch(
            `${API_BASE_URL}/payments/api/v1/PhonepeRequestPayment`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestData),
            }
          );

          const responseData = await response.json();
          //console.log(responseData);
          const handleAuthError = handleAuthExpiry(response);
          if (handleAuthError === true) {
            logout();
            return;
          }
          if (responseData && responseData.result) {
            try {
              const resultData = JSON.parse(responseData.result);

              // PHONE PE
              if (
                resultData.data &&
                resultData.data.instrumentResponse &&
                resultData.data.instrumentResponse.redirectInfo
              ) {
                //STRIPE PAYMENT if (resultData && resultData?.url) {
                const redirectURL =
                  resultData.data.instrumentResponse.redirectInfo.url;
                // //console.log(redirectURL);
                // Redirect the user to the provided URL
                setPaymentLoading(false);

                window.location.href = redirectURL;
              } else {
                setPaymentLoading(false);

                console.error(
                  "Invalid response format or missing redirect URL."
                );
                // Handle the case where the response does not contain a valid redirect URL.
              }
            } catch (error) {
              setPaymentLoading(false);

              console.error("Error parsing JSON from result field:", error);
              // Handle any JSON parsing errors that may occur.
            }
          } else {
            setPaymentLoading(false);

            console.error("Invalid response format or missing result field.");
          }
        } catch (e) {
          setPaymentLoading(false);

          //console.log(e)
        }
      }
    }
  };

  const itemQuantityExists = async (sku, currqty, viewProduct) => {
    try {
      // setLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/get?sku=${sku}`
      );
      if (response.ok) {
        const data = await response.json();
        if (viewProduct === true) {
          return data?.result[0]?.productId;
        }
        if (data?.result[0]?.productSKUs[0]?.quantity >= currqty) {
          return true;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const calculateAllProductQty = async (cartData) => {
    //console.log(cartData)
    for (const item of cartData) {
      const result = await itemQuantityExists(item.sku, item.qty);
      if (!result) {
        toastRef.current.showToast(
          `${item.title} is not available. Try lowering the quantity`,
          "error"
        );
        return false;
      }
    }
    //console.log(cartData)
    // successAlert('', 'Succcess');
    window.scrollTo(0, 0);
    setTab("address");
    return true;
  };

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const toastRef = useRef();

  return (
    <>
      <ToastComponent ref={toastRef} timeout={8000} />

      <CustomerLayout>
        {!isAuth() ? (
          <div className="customer-login-alert-component">
            <div className="customer-login-alert-component-head">
              Please Login to Access
            </div>
          </div>
        ) : (
          <div className="customer-cart-layout">
            {tab === "cart" &&
              (cartLoading ? (
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", lg: "row", gap: "2rem" },
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="65%"
                      height={300}
                      minWidth={300}
                    />
                    <Skeleton variant="rectangular" width="25%" height={700} />
                  </Box>
                </div>
              ) : !cartData[0]?.cartId ? (
                <div>
                  <EmptyPage desc="Your shopping cart is empty." />
                </div>
              ) : (
                <div className="customer-cart">
                  <div className="customer-cart-products">
                    <div className="customer-cart-products-head">
                      <div className="customer-cart-products-heading">
                        {" "}
                        My Cart
                      </div>
                      {/* <div className='customer-cart-products-clear-cart' onClick={clearCart}>Clear Cart</div> */}
                    </div>
                    {cartData?.map((item) => (
                      <CustomerCartCard
                        key={item.id}
                        {...item}
                        // sellingPrice={item?.sellingPrice+100}
                        loading={loading}
                        setLoading={setLoading}
                        updateCartData={updateCartData}
                        deleteItemFromCart={deleteItemFromCart}
                        itemQuantityExists={itemQuantityExists}
                      />
                    ))}
                  </div>
                  <div className="customer-cart-pricing">
                    <div className="customer-cart-pricing-coupons">
                      {!selectedCouponApply && (
                        <div className="customer-cart-pricing-coupons-check-field">
                          <div className="customer-cart-pricing-coupons-head">
                            {/* <Ticket /> */}
                            <img loading="lazy" src={ticket} alt="" />
                            <div className="apply-coupon-title">
                              Apply Coupons
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "90%",
                              margin: "0 auto",
                              marginBottom: "10px",
                            }}
                            className="discount-cart-fields"
                          >
                            <TextField
                              style={{ borderRadius: "0", maxHeight: "36px" }}
                              fullWidth
                              variant="outlined"
                              placeholder="Enter discount code"
                              value={couponCodeText}
                              onChange={(e) => {
                                setCouponCodeText(e.target.value);
                              }}
                            />
                            <Button
                              style={{
                                float: "right",
                                background:
                                  "linear-gradient(90deg, #FD2A80 0%, #FE575C 100%)",
                                color: "white",
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Apply
                            </Button>
                          </div>
                          {selectedCouponApply?.amount > 0 && (
                            <div className="customer-cart-pricing-coupons-head">
                              {/* <Ticket /> */}
                              <img loading="lazy" src={ticket} alt="" />
                              <div>
                                {" "}
                                Rs {selectedCouponApply?.amount} Coupon Applied
                              </div>
                            </div>
                          )}
                          <Accordion
                            expanded={expanded}
                            onChange={toggleAccordion}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography style={{ color: "#FD2A80" }}>
                                {" "}
                                View All Offers
                              </Typography>
                            </AccordionSummary>
                            {/* <AccordionDetails
                              style={{ maxHeight: "300px", overflow: "scroll" }}
                            >
                            
                            {filteredDiscount
                                ?.filter(
                                  (discount) =>
                                    totalAmount > discount.minAmountCart
                                )
                                .map((discount) => {
                                  return (
                                   
                                    <div className="discount-card">
                                      <div>
                                        <div className="discount-head">
                                          Flat ₹{discount.amount} off{" "}
                                        </div>
                                        <div className="discount-desc">
                                          use code {discount.couponCode} & get{" "}
                                          <span>FLAT ₹{discount.amount}</span>{" "}
                                          off on orders above Rs.
                                          {discount.minAmountCart}{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="discount-button"
                                        onClick={() => {
                                          setCouponCodeText("");
                                          setFilteredDiscount(discountData);
                                          setSelectedCouponApplied(discount);
                                          setExpanded(false);
                                        }}
                                      >
                                        Apply
                                      </div>
                                    </div>
                                  );
                                })}
                                
                            </AccordionDetails> */}
                            <AccordionDetails
                              style={{ maxHeight: "300px", overflow: "scroll" }}
                            >
                              {filteredDiscount.length > 0 ? (
                                filteredDiscount
                                  ?.filter(
                                    (discount) =>
                                      totalAmount > discount.minAmountCart
                                  )
                                  .map((discount) => (
                                    <div
                                      className="discount-card"
                                      key={discount.id}
                                    >
                                      <div>
                                        <div className="discount-head">
                                          Flat ₹{discount.amount} off{" "}
                                        </div>
                                        <div className="discount-desc">
                                          use code {discount.couponCode} & get{" "}
                                          <span>FLAT ₹{discount.amount}</span>{" "}
                                          off on orders above Rs.
                                          {discount.minAmountCart}{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="discount-button"
                                        onClick={() => {
                                          setCouponCodeText("");
                                          setFilteredDiscount(discountData);
                                          setSelectedCouponApplied(discount);
                                          setExpanded(false);
                                        }}
                                      >
                                        Apply
                                      </div>
                                    </div>
                                  ))
                              ) : (
                                <div>No offers available </div>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}

                      {selectedCouponApply && (
                        <div className="customer-cart-pricing-coupons-check-field">
                          <div className="customer-cart-pricing-coupons-head">
                            {/* <Ticket /> */}
                            <img loading="lazy" src={ticket} alt="" />
                            <div className="apply-coupon-title">
                              Apply Coupons
                            </div>
                          </div>

                          {selectedCouponApply.amount > 0 && (
                            <div className="customer-cart-pricing-coupons-head">
                              <div className="cart-coupon-applied-remove-field">
                                <div className="coupon-applied-title">
                                  <div className="coupon-applied-head">
                                    Rs {selectedCouponApply?.couponCode} Coupon
                                    Applied
                                  </div>
                                  <div className="coupon-applied-description">
                                    Offer applied on the bill
                                  </div>
                                </div>
                                <div
                                  className="discount-button"
                                  onClick={() => {
                                    setSelectedCouponApplied();
                                  }}
                                >
                                  Remove
                                </div>
                              </div>
                            </div>
                          )}
                          <Accordion
                            expanded={expanded}
                            onChange={toggleAccordion}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography style={{ color: "#FD2A80" }}>
                                {" "}
                                View All Offers
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{ maxHeight: "300px", overflow: "scroll" }}
                            >
                              {filteredDiscount
                                ?.filter(
                                  (discount) =>
                                    totalAmount > discount.minAmountCart
                                )
                                .map((discount) => {
                                  return (
                                    <div className="discount-card">
                                      <div>
                                        <div className="discount-head">
                                          Flat ₹{discount.amount} off{" "}
                                        </div>
                                        <div className="discount-desc">
                                          use code {discount.couponCode} & get{" "}
                                          <span>FLAT ₹{discount.amount}</span>{" "}
                                          off on orders above Rs.
                                          {discount?.minAmountCart}{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="discount-button"
                                        onClick={() => {
                                          setSelectedCouponApplied(discount);
                                          setExpanded(false);
                                        }}
                                      >
                                        Apply
                                      </div>
                                    </div>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}

                      <div className="cart-checkout-field">
                        <div>Payment Details</div>
                        <div className="cart-checkout-row">
                          <div className="cart-checkout-amount-text">
                            Subtotal{" "}
                            <span className="items-count">
                              ({cartData?.length} Items)
                            </span>
                          </div>
                          <div className="cart-checkout-amount">
                            ₹ {totalAmount}
                          </div>
                        </div>
                        <div className="cart-checkout-row">
                          <div className="cart-checkout-amount-text"> GST</div>
                          <div className="cart-checkout-amount">
                            {" "}
                            ₹{totalGst?.toFixed(2)}
                          </div>
                        </div>
                        <div className="cart-checkout-row">
                          <div className="cart-checkout-amount-text">
                            {" "}
                            Shipping Cost
                          </div>
                          <div className="cart-checkout-amount">
                            {" "}
                            ₹{shippingAmount}
                          </div>
                        </div>
                        {/* <div className="cart-checkout-row">
                                    <div className="cart-checkout-amount-text"> Discount</div>
                                    <div className="cart-checkout-amount-discount">- ₹{totalDiscount}</div>
                                </div> */}
                        <div className="cart-checkout-row">
                          <div className="cart-checkout-amount-text">
                            Discount
                          </div>
                          <div className="cart-checkout-amount-discount">
                            - ₹
                            {selectedCouponApply?.amount > 0
                              ? selectedCouponApply?.amount
                              : 0}
                          </div>
                        </div>
                        <div className="cart-checkout-row">
                          <div className="cart-checkout-amount-text">
                            <input
                              type="checkbox"
                              value={includeWalletAmount}
                              checked={includeWalletAmount}
                              onChange={() => {
                                setIncludeWalletAmount(!includeWalletAmount);
                              }}
                            />
                            Wallet Balance
                          </div>
                          <div className="cart-checkout-amount-discount">
                            - ₹{giftCardAmount > 0 ? giftCardAmount : 0}
                          </div>
                        </div>

                        {includeWalletAmount && (
                          <>
                            <div></div>
                            <Divider style={{ marginTop: "1rem" }} />
                            <div className="cart-checkout-row">
                              <div className="cart-checkout-amount-text">
                                {/* <div className="cart-checkout-amount-discount"> */}
                                Remaining Wallet Amount :
                              </div>
                              <span className="cart-checkout-amount-discount">
                                ₹
                                {walletAmount -
                                  (totalAmount +
                                    totalGst +
                                    shippingAmount -
                                    totalDiscount -
                                    (selectedCouponApply?.amount > 0
                                      ? selectedCouponApply?.amount
                                      : 0)) >
                                0
                                  ? (
                                      walletAmount -
                                      (totalAmount +
                                        totalGst +
                                        shippingAmount -
                                        totalDiscount -
                                        (selectedCouponApply?.amount > 0
                                          ? selectedCouponApply?.amount
                                          : 0))
                                    ).toFixed(2)
                                  : 0}
                              </span>
                              {/* </div> */}
                            </div>
                          </>
                        )}
                        <div className="cart-checkout-row-dark">
                          <div className="cart-checkout-amount-text">
                            To Pay
                          </div>
                          <div className="cart-checkout-amount">
                            ₹{" "}
                            {(
                              totalAmount +
                              totalGst +
                              shippingAmount -
                              totalDiscount -
                              (selectedCouponApply?.amount > 0
                                ? selectedCouponApply?.amount
                                : 0) -
                              walletAmount
                            ).toFixed(2) > 0
                              ? (
                                  totalAmount +
                                  totalGst +
                                  shippingAmount -
                                  totalDiscount -
                                  (selectedCouponApply?.amount > 0
                                    ? selectedCouponApply?.amount
                                    : 0) -
                                  walletAmount
                                ).toFixed(2)
                              : 0}
                          </div>
                        </div>
                      </div>
                      {selectedCouponApply?.amount.length > 0 && (
                        <div className="discount-sucess-field">
                          <>
                            
                            🎉 Great! You are saving ₹
                            {totalDiscount +
                              parseFloat(
                                selectedCouponApply?.amount > 0
                                  ? selectedCouponApply?.amount
                                  : 0
                              )}
                            in total
                          </>
                        </div>
                      )}
                      <div className="checkout-btn-secn">
                        <div className="total-price-saving">
                          {/* <div> Total</div> */}
                          <div>
                            ₹{" "}
                            {/* {totalAmount +
                              totalGst +
                              shippingAmount -
                              totalDiscount -
                              (selectedCouponApply?.amount > 0
                                ? selectedCouponApply?.amount
                                : 0)} */}
                            {(
                              totalAmount +
                              totalGst +
                              shippingAmount -
                              totalDiscount -
                              (selectedCouponApply?.amount > 0
                                ? selectedCouponApply?.amount
                                : 0) -
                              walletAmount
                            ).toFixed(2) > 0
                              ? (
                                  totalAmount +
                                  totalGst +
                                  shippingAmount -
                                  totalDiscount -
                                  (selectedCouponApply?.amount > 0
                                    ? selectedCouponApply?.amount
                                    : 0) -
                                  walletAmount
                                ).toFixed(2)
                              : 0}
                          </div>
                        </div>
                        <Button
                          onClick={handleProceedToAddress}
                          style={{
                            background:
                              "linear-gradient(90deg, #FD2A80 0%, #FE575C 100%)",
                            color: "white",
                          }}
                        >
                          Proceed to CheckOut
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {tab === "address" && (
              <div className="customer-cart">
                <div className="customer-cart-products-address-container">
                  <CustomerAddressModal
                    open={openModal}
                    onClose={handleModalClose}
                    edit={false}
                    fetchAddressData={fetchAddressData}
                  />
                  <div className="customer-address-card-layout">
                    <div className="customer-cart-products-head">
                      <div className="customer-cart-products-heading">
                        {" "}
                        Delivery Address
                      </div>
                      <div className="customer-cart-products-clear-cart"></div>
                    </div>
                    <div className="cart-address">
                      {addressData?.map((address) => (
                        <FormControl key={address.id} component="fieldset">
                          <RadioGroup
                            aria-label="address"
                            name="address-radio-group"
                            value={
                              selectedAddress
                                ? selectedAddress.id.toString()
                                : ""
                            }
                            onChange={handleAddressChange}
                          >
                            <FormControlLabel
                              value={address.id.toString()}
                              control={<Radio />}
                              label={
                                <div className="address-details-cart">
                                  <div>{address.contactName}</div>
                                  <div>{address.addressLine1}</div>
                                  <div>{address.city}</div>
                                  <div>{address.pincode}</div>
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      ))}
                      <button
                        className="add-address-cart"
                        onClick={handleModalOpen}
                      >
                        + Add Address
                      </button>
                    </div>
                  </div>
                  <div className="customer-address-card-layout">
                    <div className="customer-cart-products-head">
                      <div className="customer-cart-products-heading">
                        {" "}
                        Shipping Address
                      </div>
                      <div className="customer-cart-products-clear-cart"></div>
                    </div>
                    <div className="cart-address">
                      {addressData?.map((address) => (
                        <FormControl key={address.id} component="fieldset">
                          <RadioGroup
                            aria-label="address"
                            name="address-radio-group"
                            value={
                              selectedShippingAddress
                                ? selectedShippingAddress.id.toString()
                                : ""
                            }
                            onChange={handleShippingAddressChange}
                          >
                            <FormControlLabel
                              value={address.id.toString()}
                              control={<Radio />}
                              label={
                                <div className="address-details-cart">
                                  <div>{address.contactName}</div>
                                  <div>{address.addressLine1}</div>
                                  <div>{address.city}</div>
                                  <div>{address.pincode}</div>
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      ))}
                      <button
                        className="add-address-cart"
                        onClick={handleModalOpen}
                      >
                        + Add Address
                      </button>
                    </div>
                  </div>
                </div>
                <div className="customer-cart-pricing-address-tab">
                  {/* <div className="cart-order-summary-text">
                            Order Summary <span className="items-count">({cartData?.length} Items)</span>
                        </div> */}
                  <div className="cart-checkout-field-address-tab">
                    <div>Payment Details</div>
                    <div className="cart-checkout-row">
                      <div className="cart-checkout-amount-text">
                        Subtotal{" "}
                        <span className="items-count">
                          ({cartData?.length} Items)
                        </span>
                      </div>
                      <div className="cart-checkout-amount">
                        ₹ {totalAmount}
                      </div>
                    </div>
                    <div className="cart-checkout-row">
                      <div className="cart-checkout-amount-text"> GST</div>
                      <div className="cart-checkout-amount">
                        {" "}
                        ₹{totalGst?.toFixed(2)}
                      </div>
                    </div>
                    <div className="cart-checkout-row">
                      <div className="cart-checkout-amount-text">
                        {" "}
                        Shipping Cost
                      </div>
                      <div className="cart-checkout-amount">
                        {" "}
                        ₹{shippingAmount}
                      </div>
                    </div>
                    {/* <div className="cart-checkout-row">
                                    <div className="cart-checkout-amount-text"> Discount</div>
                                    <div className="cart-checkout-amount-discount">- ₹{totalDiscount}</div>
                                </div> */}
                    <div className="cart-checkout-row">
                      <div className="cart-checkout-amount-text">Discount</div>
                      <div className="cart-checkout-amount-discount">
                        - ₹
                        {selectedCouponApply?.amount > 0
                          ? selectedCouponApply?.amount
                          : 0}
                      </div>
                    </div>
                    <div className="cart-checkout-row">
                      <div className="cart-checkout-amount-text">
                        Gift Card Applied
                      </div>
                      <div className="cart-checkout-amount-discount">
                        - ₹
                        {walletAmount >
                        totalAmount +
                          totalGst +
                          shippingAmount -
                          totalDiscount -
                          (selectedCouponApply?.amount > 0
                            ? selectedCouponApply?.amount
                            : 0)
                          ? totalAmount +
                            totalGst +
                            shippingAmount -
                            totalDiscount -
                            (selectedCouponApply?.amount > 0
                              ? selectedCouponApply?.amount
                              : 0)
                          : walletAmount}
                      </div>
                    </div>
                    <div className="cart-checkout-row-dark">
                      <div className="cart-checkout-amount-text">To Pay</div>
                      <div className="cart-checkout-amount">
                        ₹{" "}
                        {(totalAmount +
                          totalGst +
                          shippingAmount -
                          totalDiscount -
                          (selectedCouponApply?.amount > 0
                            ? selectedCouponApply?.amount
                            : 0) >
                        walletAmount
                          ? totalAmount +
                            totalGst +
                            shippingAmount -
                            totalDiscount -
                            (selectedCouponApply?.amount > 0
                              ? selectedCouponApply?.amount
                              : 0) -
                            walletAmount
                          : 0
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="checkout-btn-secn">
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    {!paymentLoading && (
                      <Button
                        style={{
                          background:
                            "linear-gradient(90deg, #FD2A80 0%, #FE575C 100%)",
                          color: "white",
                        }}
                        onClick={() => {
                          selectedAddress && selectedShippingAddress && pay();
                        }}
                      >
                        Place your order
                      </Button>
                    )}
                    {paymentLoading && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* {tab === 'tab' && <CustomerHeading text='New Arrivals' viewAll nav='/' />} */}
            {/* {tab === 'tab' && productData && <HorizontalScroller productData={productData} customerProductCard initialItems={4} />} */}

            {/* {tab === 'tab' && <CustomerHeading text='Recently viewed ' viewAll nav='/' />} */}
            {/* {tab === 'tab' && productData && <HorizontalScroller productData={productData} customerProductCard initialItems={4} />} */}
          </div>
        )}
      </CustomerLayout>
    </>
  );
}

export default CustomerCart;

function CustomerCartCard({ loading, setLoading, ...props }) {
  const navigate = useNavigate();
  const toastRef = useRef();
  function CustomerCartQuantity(props) {
    const [qty, setQty] = useState(props.itemQuantity);
    const [loading, setLoading] = useState(false);

    const handleMinusClick = () => {
      setLoading(true);
      const newQty = qty > 1 ? qty - 1 : 1;
      setQty(newQty);
      props.updateCartData(props.sku, newQty);
      setLoading(false);
    };

    const handlePlusClick = async () => {
      setLoading(true);
      const exists = await props.itemQuantityExists(props.sku, qty + 1, false);
      if (exists === true) {
        const newQty = qty + 1;
        setQty(newQty);
        props.updateCartData(props.sku, newQty);
      } else {
        toastRef.current.showToast(
          "Could not add to cart. Quantity Error.",
          "error"
        );
      }
      setLoading(false);
    };

    return (
      <div className="customer-cart-card-quantity">
        {props.itemQuantity > 1 && (
          <Minus style={{ cursor: "pointer" }} onClick={handleMinusClick} />
        )}
        {props.itemQuantity <= 1 && <Minus style={{ color: "grey" }} />}
        {!loading && props.itemQuantity}
        {loading && (
          <CircularProgress
            style={{
              color: "gray",
              width: "40px",
              height: "30px",
              margin: "0px 12px",
            }}
          />
        )}
        <Plus style={{ cursor: "pointer" }} onClick={handlePlusClick} />
      </div>
    );
  }

  return (
    <div className="customer-cart-card-layout">
      <ToastComponent ref={toastRef} timeout={4000} />
      <img
        loading="lazy"
        className="customer-cart-card-image"
        src={props.imageurl}
      />
      <div className="customer-cart-card-details">
        <div
          className="customer-cart-card-name"
          style={{ cursor: "pointer" }}
          onClick={async () => {
            const view = await props.itemQuantityExists(
              props.sku,
              props.qty,
              true
            );
            navigate(`/productDetail/${view}`);
          }}
        >
          {props.title}
        </div>
        {props.size&&<div className="customer-cart-card-size">Size {props.size}</div>}
        <CustomerCartQuantity
          itemQuantity={props.qty}
          itemQuantityExists={() =>
            props.itemQuantityExists(props.sku, props.qty, false)
          }
          {...props}
        />
      </div>
      {/* <div style={{flex:1}}></div> */}
      <div className="customer-cart-card-prices">
        <div className="customer-cart-card-price">
          Rs. {parseFloat(props.price)}
        </div>
        <Trash
          style={{ width: "24px", height: "24px", cursor: "pointer" }}
          onClick={() => {
            props.deleteItemFromCart(props.itemId);
          }}
        />
      </div>
    </div>
  );
}
