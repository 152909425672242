import React, { useState, useEffect, useContext, useRef } from "react";
import CustomerLayout from "../components/CustomerLayout";
import CustomerHeading from "../components/CustomerHeading";
import Rectangle from "../Images/Rectangle.png";
import date from "../Images/date.png";
import Logo from "../Images/MyhrakiLogoC.png";
import { Star } from "@phosphor-icons/react/dist/ssr";
import "../styles/AdminBuyGC.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomerContext } from "../context/CustomerContext";
import { API_BASE_URL } from "../App";
import { Diversity1 } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import ThankyouGiftCard from "../Images/Thankyou.png";
import Festival from "../Images/Fesitival.png";
import congratsGiftCard from "../Images/Congratulation.png";
import HappybirthdayCard from "../Images/HappyBirthday.png";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomerLoginModal from "../components/CustomerLoginModal";
import { Divide } from "@phosphor-icons/react";
import { Divider } from "@mui/joy";
import { CircularProgress, TextField } from "@mui/material";
import GCBanner from "../Images/GCBanner.png";
import GiftCard from "../Images/Gift Card New Option 1.jpg";
import { AdminContext } from "../context/AdminContext";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import ToastComponent from "../components/ToastComponent";

function AdminBuyGC() {
  const { adminData, accessToken } = useContext(AdminContext);
  const [loading, setLoading] = useState(false)
  const validationSchemaMailCard = yup.object({
    amount: yup
      .number()
      .min(100, "Amount must be greater than 100")
      .max(100000, "Amount must be less than 100000")
      .required("Amount is required"),
    receptanceName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z- ]+$/, "Name must contain only alphabetic characters")
      .required("Name is required"),
    receptanceEmail: yup
      .string()
      .trim()
      .matches(/.*@.*/, "Email must contain @")
      .email("Invalid email format"),
    receptancePhone: yup
      .string()
      .trim()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Number is required"),
    from: yup
      .string()
      .trim()
      .matches(/^[A-Za-z- ]+$/, "Name must contain only alphabetic characters")
      .required("From is required"),
    message: yup
      .string()
      .trim()
      .max(1000, "Message must not exceed 100 characters")
      .required("Message is required"),
    deliveryDate: yup.date().required("Date is required"),
    code: yup
    .string()
    .trim()
    .required("Voucher Code is required")
    .min(26,"Voucher code must be 26 of characters")
    .max(26,"Voucher code must be 26 of characters"),
    
  });

  const formikMailCard = useFormik({
    initialValues: {
      amount: "",
      receptancePhone: "",
      receptanceName: "",
      receptanceEmail: "",
      from: "",
      message: "",
      deliveryDate: "",
      code: "",
    },
    validationSchema: validationSchemaMailCard,
    onSubmit: async (values, { resetForm }) => {
      if (!adminData?.result?.userID) {
        // setLoginModalOpen(true);
        return;
      }
      
      await pay(values);
      confirmPayment(values);
      
      
      resetForm();
      setAmount(0);
      setMessage("Your personal message will be displayed here");
      
    
        
     
    },
  });
  const [giftCardOptions, setGiftCardOptions] = useState([]);
  const toastRef = useRef();
  const giftCardList = [
    {
      idx: 1,
      image: ThankyouGiftCard,
      description: "Thank You",
    },
    {
      idx: 2,
      image: HappybirthdayCard,
      description: "Happy Birthday!!",
    },
    {
      idx: 3,
      image: Festival,
      description: "Happy Festival",
    },
    {
      idx: 4,
      image: congratsGiftCard,
      description: "Congratualtions!!!!",
    },
  ];

  const [buttonActive, setButtonActive] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  //   const [loginModalOpen, setLoginModalOpen] = React.useState(false)/;

  const [selectedGiftCardImage, setSelectedGiftCardImage] = useState(GiftCard);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState(
    "Your personal message will be displayed here"
  );

  const [amount, setAmount] = useState("");
  const handleInputChange1 = (event) => {
    setMessage(event.target.value);
  };
  const handleInputChange2 = (event) => {
    setAmount(event.target.value);
    event.preventDefault();
  };

  const pay = async (values) => {
    console.log("Pay Called");
    console.log(values);
    setLoading(true)
    try {
      const response = await fetch(
        `${API_BASE_URL}/coupons/api/v1/createVoucherByAdmin`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application / json - patch + json",
          },
          body: JSON.stringify({
            userid: adminData?.result?.userID,
            userName: adminData?.result?.firstName,
            userPhoneNumber: adminData?.result?.mobileNumber,
            userEmailid: adminData?.result?.email,
            amount: values?.amount,
            receptacename: values?.receptanceName,
            receptaceEmail: values?.receptanceEmail,
            receptacePhone: `${values?.receptancePhone}`,
            imageName: selectedGiftCardImage,
            message: values?.message,
            deliveryDate: values?.deliveryDate,
            voucherCode: values?.code,
          }),
        }
      );
      const data = await response.json();
      const urlData = JSON.parse(data?.result);
      const urlPath = JSON.parse(urlData?.result);
      const url = urlPath.data.instrumentResponse.redirectInfo.url;
      window.location.href = url;
    } catch {}
  };

  const confirmPayment = async (values) => {
    
    try {
      const response = await fetch(
        `${API_BASE_URL}/coupons/api/v1/ConfirmPayment`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify({
            "userid": adminData?.result?.userID,
            "paymentId":"",
          }),
         
        }
        
      );
      const data = await response.json();
      
      if (data?.result?.paymentStatus === "Completed") {
        toastRef.current.showToast("Gift Card purchased successfully!", "success");
      } else {
        toastRef.current.showToast("Error in purchasing Gift Card", "error");
      }
      setLoading(false)
    } catch (error) {
      console.error("Error confirming payment:", error);
    }
  };

  const today = new Date();

  return (
    <AdminLayoutAdjustment>
      {/* <CustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => {
          setLoginModalOpen(false);
        }}
      /> */}
      <ToastComponent ref={toastRef} timeout={4000} />
      <div className="Ad-GiftCardLayout">
        <div className="Ad-GiftCardLayout1">
          <div className="Ad-gc-heading">GIFT CARD</div>
          <div className="Ad-gc-main-container">
            <div className="Ad-gc-box-container">
              <img src={GiftCard} className="Ad-Box-image" />
              <tr className="Ad-gc-tr1">
                <td className="Ad-gc-td1">
                  <img
                    className="Ad-imgPay"
                    src={Logo}
                    style={{ height: "20px" }}
                  />
                  <div className="Ad-gc-pay">Pay Gift Card</div>
                </td>
                <td className="Ad-gc-td2">₹{amount}</td>
              </tr>
              <div className="Ad-Box-message">{message}</div>
            </div>
            <div className="Ad-eGift-card">
              <div className="Ad-My-text1">Myhraki Pay eGift Card</div>
              <div className="Ad-My-text2">By Myhraki</div>

              <Divider className="Ad-gc-divider" />

              <Divider className="Ad-gc-divider" />

              <div className="Ad-MailCard-table">
                <form
                  onSubmit={formikMailCard.handleSubmit}
                  className="Ad-table-detail"
                >
                  <div className="Ad-gc-div-input">
                    <label className="Ad-gc-label">Amount</label>
                    <div className="Ad-gc-img-input">
                      <img src={Rectangle} className="Ad-gc-icon" />
                      <input
                        type="text"
                        name="amount"
                        value={formikMailCard.values.amount}
                        onChange={formikMailCard.handleChange}
                        className="Ad-gc-withimage-input"
                        onInput={handleInputChange2}
                        inputMode="numeric"
                      />
                    </div>
                  </div>
                  {formikMailCard.touched.amount && (
                    <div style={{ color: "red" }} id="Ad-gc-error">
                      {formikMailCard?.errors?.amount}
                    </div>
                  )}

                  <div className="Ad-gc-div-input">
                    <label className="Ad-gc-label">To</label>
                    <div className="Ad-gc-to-grid">
                      <div className="Ad-gc-toinput">
                        <input
                          type="text"
                          name="receptanceName"
                          className="Ad-gc-e-input"
                          id="Ad-gc-input-field"
                          placeholder="Enter recipient Name"
                          value={formikMailCard.values.receptanceName}
                          onChange={formikMailCard.handleChange}
                        />
                        {formikMailCard.touched.receptanceName && (
                          <div style={{ color: "red" }} id="Ad-gc-error">
                            {formikMailCard?.errors?.receptanceName}
                          </div>
                        )}
                      </div>
                      <div className="Ad-gc-toinput">
                        <input
                          type="email"
                          name="receptanceEmail"
                          id="Ad-gc-input-field"
                          className="Ad-gc-e-input"
                          placeholder="Enter recipient e-mail address  "
                          value={formikMailCard.values.receptanceEmail}
                          onChange={formikMailCard.handleChange}
                        />
                        {formikMailCard.touched.receptanceEmail && (
                          <div style={{ color: "red" }} id="Ad-gc-error">
                            {formikMailCard?.errors?.receptanceEmail}
                          </div>
                        )}
                      </div>
                      <div className="Ad-gc-toinput">
                        <input
                          type="text"
                          name="receptancePhone"
                          className="Ad-gc-e-input"
                          id="Ad-gc-input-field"
                          placeholder="Enter recipient phone number  "
                          value={formikMailCard.values.receptancePhone}
                          onChange={formikMailCard.handleChange}
                        />
                        {formikMailCard.touched.receptancePhone && (
                          <div style={{ color: "red" }} id="Ad-gc-error">
                            {formikMailCard?.errors?.receptancePhone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="Ad-gc-div-input">
                    <label className="Ad-gc-label">From</label>
                    <div className="Ad-fromInputMain">
                      <input
                        type="text"
                        name="from"
                        className="Ad-gc-e-inputfrom"
                        id="Ad-gc-input-field"
                        placeholder="Your name"
                        value={formikMailCard.values.from}
                        onChange={formikMailCard.handleChange}
                      />
                    </div>
                  </div>
                  {formikMailCard.touched.from && (
                    <div style={{ color: "red" }} id="Ad-gc-error">
                      {formikMailCard?.errors?.from}
                    </div>
                  )}

                  <div className="Ad-gc-div-input">
                    <label className="Ad-e-messagelabel">Message</label>
                    <textarea
                      className="Ad-gc-msginput-field"
                      id="Ad-gc-input-field"
                      type="text"
                      name="message"
                      placeholder="Hope you enjoy this Myhraki Gift Pay Card!"
                      value={formikMailCard.values.message}
                      onChange={formikMailCard.handleChange}
                      onInput={handleInputChange1}
                      rows={4}
                    />
                  </div>
                  {formikMailCard.touched.message && (
                    <div style={{ color: "red" }} id="Ad-gc-error">
                      {formikMailCard?.errors?.message}
                    </div>
                  )}

                  <div className="Ad-gc-div-input">
                    <label className="Ad-e-messagelabel">Voucher Code</label>
                    <input
                      className="Ad-gc-e-inputfrom"
                      id="Ad-gc-input-field"
                      type="text"
                      name="code"
                      placeholder="Voucher Code"
                      value={formikMailCard.values.code}
                      onChange={formikMailCard.handleChange}
                      //   onInput={handleInputChange1}
                      //   rows={4}
                    />
                  </div>
                  {formikMailCard.touched.code && (
                    <div style={{ color: "red" }} id="Ad-gc-error">
                      {formikMailCard?.errors?.code}
                    </div>
                  )}
                  <div className="Ad-gc-div-input">
                    <label className="Ad-gc-date-label">Delivery Date</label>
                    <div className="Ad-gc-img-input">
                      <img src={date} className="Ad-gc-icon" />
                      {/* <DatePicker
                        calendarClassName="Ad-custom-calendar"
                        calendarContainer={document.querySelector(
                          ".date-picker"
                        )}
                        className="Ad-gc-withimage-dateinput"
                        selected={startDate}
                        name="deliveryDate"
                        onSelect={(date) => {
                          setStartDate(date);
                          formikMailCard.setFieldValue("deliveryDate", date);
                        }}
                        showPopperArrow={false}
                        minDate={today}
                        dateFormat="dd/MM/yyyy"
                      /> */}
                      {/* <input
  type="date"
  name="deliveryDate"
  className="Ad-gc-withimage-dateinput"
  value={startDate}
  onChange={(e) => {
    const date = e.target.value;
    setStartDate(date);
    formikMailCard.setFieldValue("deliveryDate", date);
  }}
  min={new Date().toISOString().split('T')[0]}
  
/> */}
                      <input
                        type="date"
                        name="deliveryDate"
                        className="Ad-gc-withimage-dateinput"
                        value={startDate}
                        onChange={(e) => {
                          const date = e.target.value;
                          setStartDate(date);
                          formikMailCard.setFieldValue("deliveryDate", date);
                        }}
                        min={new Date().toISOString().split("T")[0]}
                        style={{
                          position: "relative",
                          maxWidth: "100%",
                          width: "300px",
                        }}
                        // Set a specific width to prevent horizontal scrolling
                      />
                    </div>
                  </div>
                  {formikMailCard.touched.deliveryDate && (
                    <div style={{ color: "red" }} id="Ad-gc-error">
                      {formikMailCard?.errors?.deliveryDate}
                    </div>
                  )}

                  <div className="Ad-buttonCard">
                  {loading?<CircularProgress style={{color:'pink'}} />:<button className="Ad-cardBuy" type="submit">
                      BUY NOW
                    </button>}
                    
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayoutAdjustment>
  );
}

export default AdminBuyGC;
